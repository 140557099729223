import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/contact.vue"),
  },
  {
    path: "/strategies",
    name: "strategies",
    component: () =>
      import(/* webpackChunkName: "strategies" */ "@/views/strategies.vue"),
  },
  // {
  //   path: "/team",
  //   name: "team",
  //   component: () =>
  //     import(/* webpackChunkName: "team" */ "@/views/team.vue"),
  // },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "@/views/portfolio.vue"),
  },
  {
    path: "/esg",
    name: "esg",
    component: () =>
      import(/* webpackChunkName: "esg" */ "@/views/esg.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news.vue"),
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () =>
      import(/* webpackChunkName: "terms-conditions" */ "@/views/terms-conditions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "@/views/privacy-policy.vue"),
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: () =>
      import(/* webpackChunkName: "cookie-policy" */ "@/views/cookie-policy.vue"),
  },
  {
    path: "/manage-cookie-preferences",
    name: "manage-cookie-preferences",
    component: () =>
      import(/* webpackChunkName: "manage-cookie-preferences" */ "@/views/manage-cookie-preferences.vue"),
  },
  {
    path: "/portfolio/37-5",
    name: "portfolio/37-5",
    component: () =>
      import(/* webpackChunkName: "portfolio/37-5" */ "@/views/portfolio/37-5.vue"),
  },
  {
    path: "/portfolio/3pillar-global",
    name: "portfolio/3pillar-global",
    component: () =>
      import(/* webpackChunkName: "portfolio/3pillar-global" */ "@/views/portfolio/3pillar-global.vue"),
  },
  {
    path: "/portfolio/7s-personal",
    name: "portfolio/7s-personal",
    component: () =>
      import(/* webpackChunkName: "portfolio/7s-personal" */ "@/views/portfolio/7s-personal.vue"),
  },
  {
    path: "/portfolio/a10-capital",
    name: "portfolio/a10-capital",
    component: () =>
      import(/* webpackChunkName: "portfolio/a10-capital" */ "@/views/portfolio/a10-capital.vue"),
  },
  {
    path: "/portfolio/accounting-seed",
    name: "portfolio/accounting-seed",
    component: () =>
      import(/* webpackChunkName: "portfolio/accounting-seed" */ "@/views/portfolio/accounting-seed.vue"),
  },
  {
    path: "/portfolio/accupac",
    name: "portfolio/accupac",
    component: () =>
      import(/* webpackChunkName: "portfolio/accupac" */ "@/views/portfolio/accupac.vue"),
  },
  {
    path: "/portfolio/acg-materials",
    name: "portfolio/acg-materials",
    component: () =>
      import(/* webpackChunkName: "portfolio/acg-materials" */ "@/views/portfolio/acg-materials.vue"),
  },
  {
    path: "/portfolio/acqua-sapone",
    name: "portfolio/acqua-sapone",
    component: () =>
      import(/* webpackChunkName: "portfolio/acqua-sapone" */ "@/views/portfolio/acqua-sapone.vue"),
  },
  {
    path: "/portfolio/adscape-media",
    name: "portfolio/adscape-media",
    component: () =>
      import(/* webpackChunkName: "portfolio/adscape-media" */ "@/views/portfolio/adscape-media.vue"),
  },
  {
    path: "/portfolio/adtheorent",
    name: "portfolio/adtheorent",
    component: () =>
      import(/* webpackChunkName: "portfolio/adtheorent" */ "@/views/portfolio/adtheorent.vue"),
  },
  {
    path: "/portfolio/advanced-dental-brands",
    name: "portfolio/advanced-dental-brands",
    component: () =>
      import(/* webpackChunkName: "portfolio/advanced-dental-brands" */ "@/views/portfolio/advanced-dental-brands.vue"),
  },
  {
    path: "/portfolio/aert",
    name: "portfolio/aert",
    component: () =>
      import(/* webpackChunkName: "portfolio/aert" */ "@/views/portfolio/aert.vue"),
  },
  {
    path: "/portfolio/agere-systems",
    name: "portfolio/agere-systems",
    component: () =>
      import(/* webpackChunkName: "portfolio/agere-systems" */ "@/views/portfolio/agere-systems.vue"),
  },
  {
    path: "/portfolio/aircom",
    name: "portfolio/aircom",
    component: () =>
      import(/* webpackChunkName: "portfolio/aircom" */ "@/views/portfolio/aircom.vue"),
  },
  {
    path: "/portfolio/albertville-quality-foods",
    name: "portfolio/albertville-quality-foods",
    component: () =>
      import(/* webpackChunkName: "portfolio/albertville-quality-foods" */ "@/views/portfolio/albertville-quality-foods.vue"),
  },
  {
    path: "/portfolio/alder-biopharmaceuticals",
    name: "portfolio/alder-biopharmaceuticals",
    component: () =>
      import(/* webpackChunkName: "portfolio/alder-biopharmaceuticals" */ "@/views/portfolio/alder-biopharmaceuticals.vue"),
  },
  {
    path: "/portfolio/align-networks",
    name: "portfolio/align-networks",
    component: () =>
      import(/* webpackChunkName: "portfolio/align-networks" */ "@/views/portfolio/align-networks.vue"),
  },
  {
    path: "/portfolio/allegient-systems",
    name: "portfolio/allegient-systems",
    component: () =>
      import(/* webpackChunkName: "portfolio/allegient-systems" */ "@/views/portfolio/allegient-systems.vue"),
  },
  {
    path: "/portfolio/alteo",
    name: "portfolio/alteo",
    component: () =>
      import(/* webpackChunkName: "portfolio/alteo" */ "@/views/portfolio/alteo.vue"),
  },
  {
    path: "/portfolio/american-hardwood-industries",
    name: "portfolio/american-hardwood-industries",
    component: () =>
      import(/* webpackChunkName: "portfolio/american-hardwood-industries" */ "@/views/portfolio/american-hardwood-industries.vue"),
  },
  {
    path: "/portfolio/american-vision-partners",
    name: "portfolio/american-vision-partners",
    component: () =>
      import(/* webpackChunkName: "portfolio/american-vision-partners" */ "@/views/portfolio/american-vision-partners.vue"),
  },
  {
    path: "/portfolio/all-american-group",
    name: "all-american-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/all-american-group" */ "@/views/portfolio/all-american-group.vue"),
  },
  {
    path: "/portfolio/ampac",
    name: "ampac",
    component: () =>
      import(/* webpackChunkName: "portfolio/ampac" */ "@/views/portfolio/ampac.vue"),
  },
  {
    path: "/portfolio/amsive",
    name: "amsive",
    component: () =>
      import(/* webpackChunkName: "portfolio/amsive" */ "@/views/portfolio/amsive.vue"),
  },
  {
    path: "/portfolio/andwis",
    name: "andwis",
    component: () =>
      import(/* webpackChunkName: "portfolio/andwis" */ "@/views/portfolio/andwis.vue"),
  },
  {
    path: "/portfolio/anvis-group",
    name: "anvis-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/anvis-group" */ "@/views/portfolio/anvis-group.vue"),
  },
  {
    path: "/portfolio/aps-healthcare",
    name: "aps-healthcare",
    component: () =>
      import(/* webpackChunkName: "portfolio/aps-healthcare" */ "@/views/portfolio/aps-healthcare.vue"),
  },
  {
    path: "/portfolio/apollo-endosurgery",
    name: "apollo-endosurgery",
    component: () =>
      import(/* webpackChunkName: "portfolio/apollo-endosurgery" */ "@/views/portfolio/apollo-endosurgery.vue"),
  },
  {
    path: "/portfolio/arboc-specialty-vehicles",
    name: "arboc-specialty-vehicles",
    component: () =>
      import(/* webpackChunkName: "portfolio/arboc-specialty-vehicles" */ "@/views/portfolio/arboc-specialty-vehicles.vue"),
  },
  {
    path: "/portfolio/augmedics",
    name: "augmedics",
    component: () =>
      import(/* webpackChunkName: "portfolio/augmedics" */ "@/views/portfolio/augmedics.vue"),
  },
  {
    path: "/portfolio/aspire-pharma",
    name: "aspire-pharma",
    component: () =>
      import(/* webpackChunkName: "portfolio/aspire-pharma" */ "@/views/portfolio/aspire-pharma.vue"),
  },
  {
    path: "/portfolio/beacon-industrial-group",
    name: "beacon-industrial-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/beacon-industrial-group" */ "@/views/portfolio/beacon-industrial-group.vue"),
  },
  {
    path: "/portfolio/avi-spl",
    name: "avi-spl",
    component: () =>
      import(/* webpackChunkName: "portfolio/avi-spl" */ "@/views/portfolio/avi-spl.vue"),
  },
  {
    path: "/portfolio/barton-associates",
    name: "barton-associates",
    component: () =>
      import(/* webpackChunkName: "portfolio/barton-associates" */ "@/views/portfolio/barton-associates.vue"),
  },
  {
    path: "/portfolio/aviapartner",
    name: "aviapartner",
    component: () =>
      import(/* webpackChunkName: "portfolio/aviapartner" */ "@/views/portfolio/aviapartner.vue"),
  },
  {
    path: "/portfolio/ascent-global-logistics",
    name: "ascent-global-logistics",
    component: () =>
      import(/* webpackChunkName: "portfolio/ascent-global-logistics" */ "@/views/portfolio/ascent-global-logistics.vue"),
  },
  {
    path: "/portfolio/arvelle-therapeutics",
    name: "arvelle-therapeutics",
    component: () =>
      import(/* webpackChunkName: "portfolio/arvelle-therapeutics" */ "@/views/portfolio/arvelle-therapeutics.vue"),
  },
  {
    path: "/portfolio/armetallizing",
    name: "armetallizing",
    component: () =>
      import(/* webpackChunkName: "portfolio/armetallizing" */ "@/views/portfolio/armetallizing.vue"),
  },
  {
    path: "/portfolio/arctic-glacier-holdings",
    name: "arctic-glacier-holdings",
    component: () =>
      import(/* webpackChunkName: "portfolio/arctic-glacier-holdings" */ "@/views/portfolio/arctic-glacier-holdings.vue"),
  },
  {
    path: "/portfolio/amerijet-international",
    name: "amerijet-international",
    component: () =>
      import(/* webpackChunkName: "portfolio/amerijet-international" */ "@/views/portfolio/amerijet-international.vue"),
  },
  {
    path: "/portfolio/beco-holding-company-bhc",
    name: "beco-holding-company-bhc",
    component: () =>
      import(/* webpackChunkName: "portfolio/beco-holding-company-bhc" */ "@/views/portfolio/beco-holding-company-bhc.vue"),
  },
  {
    path: "/portfolio/beinbauer-group",
    name: "beinbauer-group",
    component: () =>
      import(/* webpackChunkName: "portfolio/beinbauer-group" */ "@/views/portfolio/beinbauer-group.vue"),
  },
  {
    path: "/portfolio/berardi",
    name: "berardi",
    component: () =>
      import(/* webpackChunkName: "portfolio/berardi" */ "@/views/portfolio/berardi.vue"),
  },
  {
    path: "/portfolio/big-tex",
    name: "big-tex",
    component: () =>
      import(/* webpackChunkName: "portfolio/big-tex" */ "@/views/portfolio/big-tex.vue"),
  },
  {
    path: "/portfolio/biovectra",
    name: "biovectra",
    component: () =>
      import(/* webpackChunkName: "portfolio/biovectra" */ "@/views/portfolio/biovectra.vue"),
  },
  {
    path: "/portfolio/bluum",
    name: "bluum",
    component: () =>
      import(/* webpackChunkName: "portfolio/bluum" */ "@/views/portfolio/bluum.vue"),
  },
  {
    path: "/portfolio/bolt",
    name: "bolt",
    component: () =>
      import(/* webpackChunkName: "portfolio/bolt" */ "@/views/portfolio/bolt.vue"),
  },
  {
    path: "/portfolio/bigsal",
    name: "bigsal",
    component: () =>
      import(/* webpackChunkName: "portfolio/bigsal" */ "@/views/portfolio/bigsal.vue"),
  },
  {
    path: "/portfolio/boostability",
    name: "boostability",
    component: () =>
      import(/* webpackChunkName: "portfolio/boostability" */ "@/views/portfolio/boostability.vue"),
  },
  {
    path: "/portfolio/brand-addition",
    name: "brand-addition",
    component: () =>
      import(/* webpackChunkName: "portfolio/brand-addition" */ "@/views/portfolio/brand-addition.vue"),
  },
  {
    path: "/portfolio/buck",
    name: "buck",
    component: () =>
      import(/* webpackChunkName: "portfolio/buck" */ "@/views/portfolio/buck.vue"),
  },
  {
    path: "/portfolio/cadica",
    name: "cadica",
    component: () =>
      import(/* webpackChunkName: "portfolio/cadica" */ "@/views/portfolio/cadica.vue"),
  },
  // {
  //   path: "/team/sami-mnaymneh",
  //   name: "sami-mnaymneh",
  //   component: () =>
  //     import(/* webpackChunkName: "team/sami-mnaymneh" */ "@/views/team/sami-mnaymneh.vue"),
  // },
  // {
  //   path: "/team/tony-tamer",
  //   name: "tony-tamer",
  //   component: () =>
  //     import(/* webpackChunkName: "team/tony-tamer" */ "@/views/team/tony-tamer.vue"),
  // },
  // {
  //   path: "/team/rick-rosen",
  //   name: "rick-rosen",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rick-rosen" */ "@/views/team/rick-rosen.vue"),
  // },
  // {
  //   path: "/team/brian-schwartz",
  //   name: "brian-schwartz",
  //   component: () =>
  //     import(/* webpackChunkName: "team/brian-schwartz" */ "@/views/team/brian-schwartz.vue"),
  // },
  // {
  //   path: "/team/stuart-aronson",
  //   name: "stuart-aronson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/stuart-aronson" */ "@/views/team/stuart-aronson.vue"),
  // },
  // {
  //   path: "/team/douglas-berman",
  //   name: "douglas-berman",
  //   component: () =>
  //     import(/* webpackChunkName: "team/douglas-berman" */ "@/views/team/douglas-berman.vue"),
  // },
  // {
  //   path: "/team/wolfgang-biedermann",
  //   name: "wolfgang-biedermann",
  //   component: () =>
  //     import(/* webpackChunkName: "team/wolfgang-biedermann" */ "@/views/team/wolfgang-biedermann.vue"),
  // },
  // {
  //   path: "/team/john-bolduc",
  //   name: "john-bolduc",
  //   component: () =>
  //     import(/* webpackChunkName: "team/john-bolduc" */ "@/views/team/john-bolduc.vue"),
  // },
  // {
  //   path: "/team/chad-buresh",
  //   name: "chad-buresh",
  //   component: () =>
  //     import(/* webpackChunkName: "team/chad-buresh" */ "@/views/team/chad-buresh.vue"),
  // },
  // {
  //   path: "/team/jay-maher",
  //   name: "jay-maher",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jay-maher" */ "@/views/team/jay-maher.vue"),
  // },
  // {
  //   path: "/team/jordan-peer-griffin",
  //   name: "jordan-peer-griffin",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jordan-peer-griffin" */ "@/views/team/jordan-peer-griffin.vue"),
  // },
  // {
  //   path: "/team/richard-siegel",
  //   name: "richard-siegel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/richard-siegel" */ "@/views/team/richard-siegel.vue"),
  // },
  // {
  //   path: "/team/rob-wolfson",
  //   name: "rob-wolfson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rob-wolfson" */ "@/views/team/rob-wolfson.vue"),
  // },
  // {
  //   path: "/team/jackson-craig",
  //   name: "jackson-craig",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jackson-craig" */ "@/views/team/jackson-craig.vue"),
  // },
  // {
  //   path: "/team/riccardo-dallolio",
  //   name: "riccardo-dallolio",
  //   component: () =>
  //     import(/* webpackChunkName: "team/riccardo-dallolio" */ "@/views/team/riccardo-dallolio.vue"),
  // },
  // {
  //   path: "/team/brendan-dolan",
  //   name: "brendan-dolan",
  //   component: () =>
  //     import(/* webpackChunkName: "team/brendan-dolan" */ "@/views/team/brendan-dolan.vue"),
  // },
  // {
  //   path: "/team/javier-casillas",
  //   name: "javier-casillas",
  //   component: () =>
  //     import(/* webpackChunkName: "team/javier-casillas" */ "@/views/team/javier-casillas.vue"),
  // },
  // {
  //   path: "/team/camilo-e-horvilleur",
  //   name: "camilo-e-horvilleur",
  //   component: () =>
  //     import(/* webpackChunkName: "team/camilo-e-horvilleur" */ "@/views/team/camilo-e-horvilleur.vue"),
  // },
  // {
  //   path: "/team/kim-leinwand-erle",
  //   name: "kim-leinwand-erle",
  //   component: () =>
  //     import(/* webpackChunkName: "team/kim-leinwand-erle" */ "@/views/team/kim-leinwand-erle.vue"),
  // },
  // {
  //   path: "/team/pankaj-gupta",
  //   name: "pankaj-gupta",
  //   component: () =>
  //     import(/* webpackChunkName: "team/pankaj-gupta" */ "@/views/team/pankaj-gupta.vue"),
  // },
  // {
  //   path: "/team/ross-hiatt",
  //   name: "ross-hiatt",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ross-hiatt" */ "@/views/team/ross-hiatt.vue"),
  // },
  // {
  //   path: "/team/matthew-lozow",
  //   name: "matthew-lozow",
  //   component: () =>
  //     import(/* webpackChunkName: "team/matthew-lozow" */ "@/views/team/matthew-lozow.vue"),
  // },
  // {
  //   path: "/team/andrew-liau",
  //   name: "andrew-liau",
  //   component: () =>
  //     import(/* webpackChunkName: "team/andrew-liau" */ "@/views/team/andrew-liau.vue"),
  // },
  // {
  //   path: "/team/matthew-kretzman",
  //   name: "matthew-kretzman",
  //   component: () =>
  //     import(/* webpackChunkName: "team/matthew-kretzman" */ "@/views/team/matthew-kretzman.vue"),
  // },
  // {
  //   path: "/team/david-hirschberg",
  //   name: "david-hirschberg",
  //   component: () =>
  //     import(/* webpackChunkName: "team/david-hirschberg" */ "@/views/team/david-hirschberg.vue"),
  // },
  // {
  //   path: "/team/markus-noe-nordberg",
  //   name: "markus-noe-nordberg",
  //   component: () =>
  //     import(/* webpackChunkName: "team/markus-noe-nordberg" */ "@/views/team/markus-noe-nordberg.vue"),
  // },
  // {
  //   path: "/team/pascal-meysson",
  //   name: "pascal-meysson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/pascal-meysson" */ "@/views/team/pascal-meysson.vue"),
  // },
  // {
  //   path: "/team/michael-mestel",
  //   name: "michael-mestel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/michael-mestel" */ "@/views/team/michael-mestel.vue"),
  // },
  // {
  //   path: "/team/fernando-marques-oliveira",
  //   name: "fernando-marques-oliveira",
  //   component: () =>
  //     import(/* webpackChunkName: "team/fernando-marques-oliveira" */ "@/views/team/fernando-marques-oliveira.vue"),
  // },
  // {
  //   path: "/team/bruce-robertson",
  //   name: "bruce-robertson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bruce-robertson" */ "@/views/team/bruce-robertson.vue"),
  // },
  // {
  //   path: "/team/duncan-priston",
  //   name: "duncan-priston",
  //   component: () =>
  //     import(/* webpackChunkName: "team/duncan-priston" */ "@/views/team/duncan-priston.vue"),
  // },
  // {
  //   path: "/team/keval-patel",
  //   name: "keval-patel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/keval-patel" */ "@/views/team/keval-patel.vue"),
  // },
  // {
  //   path: "/team/bruce-robertson",
  //   name: "bruce-robertson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bruce-robertson" */ "@/views/team/bruce-robertson.vue"),
  // },
  // {
  //   path: "/team/edward-pallesen",
  //   name: "edward-pallesen",
  //   component: () =>
  //     import(/* webpackChunkName: "team/edward-pallesen" */ "@/views/team/edward-pallesen.vue"),
  // },
  // {
  //   path: "/team/richard-stokes",
  //   name: "richard-stokes",
  //   component: () =>
  //     import(/* webpackChunkName: "team/richard-stokes" */ "@/views/team/richard-stokes.vue"),
  // },
  // {
  //   path: "/team/andrew-scotland",
  //   name: "andrew-scotland",
  //   component: () =>
  //     import(/* webpackChunkName: "team/andrew-scotland" */ "@/views/team/andrew-scotland.vue"),
  // },
  // {
  //   path: "/team/steven-schwartz",
  //   name: "steven-schwartz",
  //   component: () =>
  //     import(/* webpackChunkName: "team/steven-schwartz" */ "@/views/team/steven-schwartz.vue"),
  // },
  // {
  //   path: "/team/adam-schimel",
  //   name: "adam-schimel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/adam-schimel" */ "@/views/team/adam-schimel.vue"),
  // },
  // {
  //   path: "/team/alex-zisson",
  //   name: "alex-zisson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/alex-zisson" */ "@/views/team/alex-zisson.vue"),
  // },
  // {
  //   path: "/team/jeff-zanarini",
  //   name: "jeff-zanarini",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jeff-zanarini" */ "@/views/team/jeff-zanarini.vue"),
  // },
  // {
  //   path: "/team/ira-weidhorn",
  //   name: "ira-weidhorn",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ira-weidhorn" */ "@/views/team/ira-weidhorn.vue"),
  // },
  // {
  //   path: "/team/jaime-bergel",
  //   name: "jaime-bergel",
  //   component: () =>
  //     import(/* webpackChunkName: "team/jaime-bergel" */ "@/views/team/jaime-bergel.vue"),
  // },
  // {
  //   path: "/team/adam-belfer",
  //   name: "adam-belfer",
  //   component: () =>
  //     import(/* webpackChunkName: "team/adam-belfer" */ "@/views/team/adam-belfer.vue"),
  // },
  // {
  //   path: "/team/daniel-rosenthal-ayash",
  //   name: "daniel-rosenthal-ayash",
  //   component: () =>
  //     import(/* webpackChunkName: "team/daniel-rosenthal-ayash" */ "@/views/team/daniel-rosenthal-ayash.vue"),
  // },
  // {
  //   path: "/team/alejandra-arguello",
  //   name: "alejandra-arguello",
  //   component: () =>
  //     import(/* webpackChunkName: "team/alejandra-arguello" */ "@/views/team/alejandra-arguello.vue"),
  // },
  // {
  //   path: "/team/kenneth-borton",
  //   name: "kenneth-borton",
  //   component: () =>
  //     import(/* webpackChunkName: "team/kenneth-borton" */ "@/views/team/kenneth-borton.vue"),
  // },
  // {
  //   path: "/team/tobias-borkowski",
  //   name: "tobias-borkowski",
  //   component: () =>
  //     import(/* webpackChunkName: "team/tobias-borkowski" */ "@/views/team/tobias-borkowski.vue"),
  // },
  // {
  //   path: "/team/ignacio-blasco-lafita",
  //   name: "ignacio-blasco-lafita",
  //   component: () =>
  //     import(/* webpackChunkName: "team/ignacio-blasco-lafita" */ "@/views/team/ignacio-blasco-lafita.vue"),
  // },
  // {
  //   path: "/team/mark-bernier",
  //   name: "mark-bernier",
  //   component: () =>
  //     import(/* webpackChunkName: "team/mark-bernier" */ "@/views/team/mark-bernier.vue"),
  // },
  // {
  //   path: "/team/rodrigo-azevedo",
  //   name: "rodrigo-azevedo",
  //   component: () =>
  //     import(/* webpackChunkName: "team/rodrigo-azevedo" */ "@/views/team/rodrigo-azevedo.vue"),
  // },
  // {
  //   path: "/team/luciano-anzanello",
  //   name: "luciano-anzanello",
  //   component: () =>
  //     import(/* webpackChunkName: "team/luciano-anzanello" */ "@/views/team/luciano-anzanello.vue"),
  // },
  // {
  //   path: "/team/joao-almeida",
  //   name: "joao-almeida",
  //   component: () =>
  //     import(/* webpackChunkName: "team/joao-almeida" */ "@/views/team/joao-almeida.vue"),
  // },
  // {
  //   path: "/team/maximilien-albou",
  //   name: "maximilien-albou",
  //   component: () =>
  //     import(/* webpackChunkName: "team/maximilien-albou" */ "@/views/team/maximilien-albou.vue"),
  // },
  // {
  //   path: "/team/bernice-berschader",
  //   name: "bernice-berschader",
  //   component: () =>
  //     import(/* webpackChunkName: "team/bernice-berschader" */ "@/views/team/bernice-berschader.vue"),
  // },
  // {
  //   path: "/team/stephanie-bednar",
  //   name: "stephanie-bednar",
  //   component: () =>
  //     import(/* webpackChunkName: "team/stephanie-bednar" */ "@/views/team/stephanie-bednar.vue"),
  // },
  // {
  //   path: "/team/antoine-baudesson",
  //   name: "antoine-baudesson",
  //   component: () =>
  //     import(/* webpackChunkName: "team/antoine-baudesson" */ "@/views/team/antoine-baudesson.vue"),
  // },
  // {
  //   path: "/team/crosby-baker",
  //   name: "crosby-baker",
  //   component: () =>
  //     import(/* webpackChunkName: "team/crosby-baker" */ "@/views/team/crosby-baker.vue"),
  // },
  // {
  //   path: "/team/dominik-boehm",
  //   name: "dominik-boehm",
  //   component: () =>
  //     import(/* webpackChunkName: "team/dominik-boehm" */ "@/views/team/dominik-boehm.vue"),
  // },
  {
    path: "/news/h-i-g-growth-partners-completes-sale-of-carltonone",
    name: "h-i-g-growth-partners-completes-sale-of-carltonone",
    component: () =>
      import(/* webpackChunkName: "h-i-g-growth-partners-completes-sale-of-carltonone" */ "@/views/news/h-i-g-growth-partners-completes-sale-of-carltonone.vue"),
  },
  {
    path: "/news/h-i-g-whitehorse-europe-refinances-astek",
    name: "h-i-g-whitehorse-europe-refinances-astek",
    component: () =>
      import(/* webpackChunkName: "h-i-g-whitehorse-europe-refinances-astek" */ "@/views/news/h-i-g-whitehorse-europe-refinances-astek.vue"),
  },
  {
    path: "/news/h-i-g-realty-invests-in-last-mile-logistics-and-industrial-outdoor-storage-ios-near-stansted-airport-london",
    name: "h-i-g-realty-invests-in-last-mile-logistics-and-industrial-outdoor-storage-ios-near-stansted-airport-london",
    component: () =>
      import(/* webpackChunkName: "h-i-g-realty-invests-in-last-mile-logistics-and-industrial-outdoor-storage-ios-near-stansted-airport-london" */ "@/views/news/h-i-g-realty-invests-in-last-mile-logistics-and-industrial-outdoor-storage-ios-near-stansted-airport-london.vue"),
  },
  {
    path: "/news/h-i-g-growth-portfolio-company-worksuite-expands-leadership-team-following-record-year",
    name: "h-i-g-growth-portfolio-company-worksuite-expands-leadership-team-following-record-year",
    component: () =>
      import(/* webpackChunkName: "h-i-g-growth-portfolio-company-worksuite-expands-leadership-team-following-record-year" */ "@/views/news/h-i-g-growth-portfolio-company-worksuite-expands-leadership-team-following-record-year.vue"),
  },
  {
    path: "/news/h-i-g-capital-announces-sale-of-icg-group-to-spie-group",
    name: "h-i-g-capital-announces-sale-of-icg-group-to-spie-group",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-announces-sale-of-icg-group-to-spie-group" */ "@/views/news/h-i-g-capital-announces-sale-of-icg-group-to-spie-group.vue"),
  },
  {
    path: "/news/h-i-g-capital-signs-definitive-agreement-to-acquire-the-thermal-commercial-vehicles-division-from-valeo",
    name: "h-i-g-capital-signs-definitive-agreement-to-acquire-the-thermal-commercial-vehicles-division-from-valeo",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-signs-definitive-agreement-to-acquire-the-thermal-commercial-vehicles-division-from-valeo" */ "@/views/news/h-i-g-capital-signs-definitive-agreement-to-acquire-the-thermal-commercial-vehicles-division-from-valeo.vue"),
  },
  {
    path: "/news/h-i-g-capital-signs-definitive-agreement-to-acquire-alights-payroll-and-professional-services-business",
    name: "h-i-g-capital-signs-definitive-agreement-to-acquire-alights-payroll-and-professional-services-business",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-signs-definitive-agreement-to-acquire-alights-payroll-and-professional-services-business" */ "@/views/news/h-i-g-capital-signs-definitive-agreement-to-acquire-alights-payroll-and-professional-services-business.vue"),
  },
  {
    path: "/news/h-i-g-whitehorse-europe-finances-stordata",
    name: "h-i-g-whitehorse-europe-finances-stordata",
    component: () =>
      import(/* webpackChunkName: "h-i-g-whitehorse-europe-finances-stordata" */ "@/views/news/h-i-g-whitehorse-europe-finances-stordata.vue"),
  },
  {
    path: "/news/h-i-g-whitehorse-europe-supports-fremman-capitals-acquisition-of-connexta",
    name: "h-i-g-whitehorse-europe-supports-fremman-capitals-acquisition-of-connexta",
    component: () =>
      import(/* webpackChunkName: "h-i-g-whitehorse-europe-supports-fremman-capitals-acquisition-of-connexta" */ "@/views/news/h-i-g-whitehorse-europe-supports-fremman-capitals-acquisition-of-connexta.vue"),
  },
  {
    path: "/news/h-i-g-capital-acquires-the-spine-business-of-zimvie-rebranded-as-highridge-medical",
    name: "h-i-g-capital-acquires-the-spine-business-of-zimvie-rebranded-as-highridge-medical",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-acquires-the-spine-business-of-zimvie-rebranded-as-highridge-medical" */ "@/views/news/h-i-g-capital-acquires-the-spine-business-of-zimvie-rebranded-as-highridge-medical.vue"),
  },
  {
    path: "/news/h-i-g-capital-completes-acquisition-of-segers",
    name: "h-i-g-capital-completes-acquisition-of-segers",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-completes-acquisition-of-segers" */ "@/views/news/h-i-g-capital-completes-acquisition-of-segers.vue"),
  },
  {
    path: "/news/h-i-g-capital-completes-acquisition-of-usa-debusk",
    name: "h-i-g-capital-completes-acquisition-of-usa-debusk",
    component: () =>
      import(/* webpackChunkName: "h-i-g-capital-completes-acquisition-of-usa-debusk" */ "@/views/news/h-i-g-capital-completes-acquisition-of-usa-debusk.vue"),
  },
  // {
  //   path: "/support",
  //   name: "support",
  //   component: () => import(/* webpackChunkName: "support" */ "../views/Support.vue"),
  // },
  // {
  //   path: "/services",
  //   name: "services",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "services" */ '@/views/services.vue'
  //     )
  // },
  // {
  //   path: "/achievement",
  //   name: "achievement",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "achievement" */ '@/views/achievement.vue'
  //     )
  // },
  // {
  //   path: "/advisors",
  //   name: "advisors",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "advisors" */ '@/views/advisors.vue'
  //     )
  // },
  // {
  //   path: "/aboutus",
  //   name: "aboutus",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/aboutus.vue"),
  // },
  {
    path: '/:pathMatch(.*)',
    //访问主页的时候 重定向到index页面
    redirect: '/',
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
