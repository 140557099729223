<template>
    <layout-header></layout-header>
    <router-view />
    <layout-footer></layout-footer>
</template>

<script setup>
import LayoutHeader from "@/components/LayoutHeader";
import LayoutFooter from "@/components/LayoutFooter";
import { onMounted } from 'vue';

  onMounted(()=>{
    require('@/assets/js/index.min.js')
    require('@/assets/js/common.js')
  })
</script>

<style lang="scss">
</style>
